@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom slider styles */
.custom-range {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  background: transparent;
  outline: none;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: #2929a2;
  border-radius: 2px;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: #2929a2;
  border-radius: 2px;
}

.custom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #2929a2;
  cursor: pointer;
  margin-top: -6px;
}

.custom-range::-moz-range-thumb {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #2929a2;
  cursor: pointer;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-runnable-track {
  background: #2929a2;
}
